.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.videoBackground {
  background-color: black;
  height: 50vh;
}

.selection {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100vh;
  z-index: 30;
}

.selection > div {
    height: 40vh;
    width: 50%;
    /* background-color: #000000ad; */
    z-index: 30;

  }

  .selection > div:first-child {
    background-image: url(https://cdn.onlinewebfonts.com/svg/img_24712.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    filter: invert(1);
    width: 80px;
  }

  .selection > div:last-child {
    background-image: url(https://user-images.githubusercontent.com/1094151/29835459-106525fc-8cc0-11e7-8134-8b7eb3c79848.png);
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    filter: invert(1);
    width: 150px;
  }


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
